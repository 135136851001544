<template>
  <Combobox as="div" v-model="selected" by="id">
    <div class="relative">
      <div class="inline-flex divide-x divide-header-topbar-button rounded-md shadow-sm">
        <div class="inline-flex divide-x divide-header-topbar-button rounded-md shadow-sm">
          <ComboboxInput
            :style="`min-width: ${minWidth}px;`"
            class="inline-flex items-center rounded-l-md border border-transparent bg-header-topbar-button py-1 pl-3 pr-4 text-header-topbar-text shadow-sm text-sm font-medium focus:ring-0 focus:ring-offset-0 focus:border-transparent"
            :displayValue="(_investor) => companyStore.selectedInvestor.name"
            @change="search = $event.target.value"
            :disabled="(companyStore.user.investors.length < 2)"
          />
          <ComboboxButton
            class="inline-flex items-center rounded-r-md bg-header-topbar-button p-1 text-sm font-medium text-header-topbar-text"
          >
            <Icon
              v-if="!isLoading && companyStore.user.investors.length > 1"
              name="heroicons:chevron-down-20-solid"
              aria-hidden="true"
              class="h-5 w-5 text-white"
            />
          </ComboboxButton>
        </div>
      </div>

      <TransitionRoot
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        @after-leave="search = ''"
      >
        <ComboboxOptions
          class="absolute z-10 mt-2 w-72 sm:w-[30rem] overflow-x-auto origin-top divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div v-bind="containerProps" class="max-h-96" @scroll="handleScroll">
            <div
              v-if="investors.length === 0 && search !== '' && !isLoading"
              class="relative cursor-default select-none py-2 px-4 text-primary-500"
            >
              {{ t('words.no_search_results') }}
            </div>
            <div v-bind="wrapperProps">
              <ComboboxOption
                v-for="{ index, data } in list"
                as="template"
                :key="index"
                :value="data"
                v-slot="{ selected, active }"
                :disabled="!data.isAllowed"
              >
                <li
                  :class="[
                    active ? 'text-white bg-header-topbar-main' : 'text-gray-900',
                    data.isAllowed ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'
                  ]"
                  class="select-none p-4 text-sm"
                  v-tooltip="{
                    content: t('errors.missing_reporting_power'),
                    placement: 'bottom',
                    disabled: data.isAllowed
                  }"
                >
                  <div class="flex flex-col">
                    <div class="flex justify-between">
                      <div class="flex gap-2">
                        <p :class="selected ? 'font-semibold' : 'font-normal'">{{ data.name }}</p>
                        <p 
                          v-if="displayCoInvestorTag(data)"
                          class="inline-flex items-center rounded-md bg-gray-100 px-2 text-xs font-medium text-gray-600"
                        >
                          {{ t(`menu.ownershipTypes.${data.coInvestorType.toLowerCase()}`) }} ({{ data.coInvestorRate }}%)
                        </p>
                      </div>
                      <span v-if="selected" :class="active ? 'text-white' : 'text-primary-500'">
                        <Icon name="heroicons:check-20-solid" class="h-5 w-5" aria-hidden="true" />
                      </span>
                    </div>
                    <table :class="[active ? 'text-header-topbar-text' : 'text-gray-500', 'mt-2 text-center text-xs']">
                      <thead>
                        <tr>
                          <th
                            v-for="keyNumber in data.keyNumbers"
                            :key="keyNumber.code"
                          >
                            {{ t(`menu.key_numbers.${keyNumber.code}`) }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            v-for="keyNumber in data.keyNumbers"
                            :key="keyNumber.code"
                          >
                            {{ data.isAllowed ? keyNumber.value.display : '-' }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </li>
              </ComboboxOption>
            </div>
            <div v-if="isLoading" class="h-[96px] flex items-center justify-center">
              <IconLoader class="h-8 w-8 animate-spin" />
            </div>
          </div>
        </ComboboxOptions>
      </TransitionRoot>
    </div>
  </Combobox>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from '@headlessui/vue';
import { useCompanyStore, Investor } from '~/store/company';
import { Cookie } from '~/utils/cookies';
import { useI18n } from 'vue-i18n';
import { useVirtualList, useDebounceFn } from '@vueuse/core';
import { InvestorContactPowers, CoInvestorType } from '~/generated/operations-airfund';
import { useClientHandle } from '@urql/vue'

const { t } = useI18n()
const companyStore = useCompanyStore()
const cookieSelectedInvestorId = useCookie(Cookie.SELECTED_INVESTOR_ID)
const handle = useClientHandle()
const client = handle.client
const limit = 10

// État pour le lazy loading
const loadState = reactive({
  offset: 10,
  isLoading: false,
  hasMoreResults: true,
})

// État pour la recherche
const search = ref('')
const debouncedSearch = ref('')
const searchState = reactive({
  offset: 0,
  isLoading: false,
  hasMoreResults: true,
  results: [] as Investor[],
})

const investorAllowed = (investor: Investor) => {
  return companyStore.user.impersonationMode || investor.powers.includes(InvestorContactPowers.InvestorContactPowerReporting)
}

const isLoading = computed(() => {
  return searchState.isLoading || loadState.isLoading
})

const investors = computed(() => {
  let items = debouncedSearch.value ? searchState.results : [companyStore.selectedInvestor, ...companyStore.user.investors]
  items = items.filter((item, index, self) => self.findIndex(t => t.id === item.id) === index)
  return items.map((investor: Investor) => {
    return {
      ...investor,
      current: investor.id === companyStore.selectedInvestor.id,
      isAllowed: investorAllowed(investor)
    }
  })
})

const selected = ref(investors.value.find(option => option.current))

const minWidth = computed(() => {
  const width = (selected.value.name.length * 8) + 10
  if (width < 150) {
    return 150
  } else if (width > 425) {
    return 425
  } else {
    return width
  }
})

const displayCoInvestorTag = ((data: Investor) => {
  return data?.coInvestorType === CoInvestorType.Usufructuary || data?.coInvestorType === CoInvestorType.BareOwner
})

const { list, containerProps, wrapperProps, scrollTo } = useVirtualList(
  investors,
  {
    itemHeight: 96,
    overscan: 10
  }
)

const updateSearch = useDebounceFn((value: string) => {
  debouncedSearch.value = value
}, 500)

const resetFilters = () => {
  searchState.offset = 0
  searchState.isLoading = false
  searchState.hasMoreResults = true
  searchState.results = []
}

const fetchInvestors = async (isSearch = false) => {
  const state = isSearch ? searchState : loadState
  if (!state.hasMoreResults || state.isLoading) return

  state.isLoading = true
  try {
    const newInvestors = await repository.getInvestors({
      client,
      search: isSearch ? debouncedSearch.value : '',
      options: {
        limit,
        offset: state.offset
      },
      selectedInvestorId: isSearch ? undefined : companyStore.selectedInvestor.id
    })

    if (newInvestors.length < limit) {
      state.hasMoreResults = false
    }

    if (isSearch) {
      searchState.results = [...searchState.results, ...newInvestors]
    } else {
      companyStore.user.investors = [...companyStore.user.investors, ...newInvestors]
    }
  } finally {
    state.isLoading = false
    state.offset += limit
  }
}

const handleScroll = () => {
  if (!containerProps.ref.value) return

  const el = containerProps.ref.value
  const bottomReached = Math.round(el.scrollTop + el.clientHeight) >= Math.round(el.scrollHeight)

  if (bottomReached) {
    fetchInvestors(!!debouncedSearch.value)
  }
}

watch(selected, (selectedInv) => {
  if (selectedInv.id !== companyStore.selectedInvestor.id) {
    cookieSelectedInvestorId.value = selectedInv!.id
    window.location.reload()
  }
})

watch(search, (value: string) => {
  updateSearch(value)
})

watch(debouncedSearch, (value: string) => {
  scrollTo(0)
  resetFilters()
  if (value) {
    fetchInvestors(true)
  }
})

</script>

<style>
.v-popper__inner {
  max-width: 300px;
}
</style>
